<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80 z-10" @click="onClose">
    <div class="bg-white rounded-lg shadow w-[60%] mobile:w-[80%]" >
      <div class="flex items-start justify-between p-5 border-b rounded-t">
        <span class="font-light text-trueeye-red text-[40px] mobile:text-xl w-[80%]">{{ $t('service.retinaService') }}</span>
        <!-- <div class=" w-[30px] h-[30px] shadow-md rounded-full opacity-50 flex justify-center items-center z-10 hover:cursor-pointer "
             @click.stop="onClose">
          <img class="w-[50%] h-[50%]" :src="crossIcon" alt="">
        </div> -->
      </div>
            <div ref="container" class="px-6 snap-proximity snap-y h-[50vh] mobile:h-[65vh] overflow-y-auto"  @click.stop>
                <div ref="retinaDisease1" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 0 && Check }" class="my-6 snap-start p-3">
                    <p class="text-xl mobile:text-lg">{{ $t('service.retinaDiseaseHead1') }} </p>
                    <p class="text-xl mobile:text-lg text-[#999999]">{{ $t('service.retinaDiseaseSub1') }} </p>
                    <p class="mt-4 font-light mobile:text-sm">{{ $t('service.retinaDiseaseDetail1') }} </p>
                </div>
                <div ref="retinaDisease2" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 1 && Check }"  class="my-6 snap-start p-3 ">
                    <p class="text-xl mobile:text-lg ">{{ $t('service.retinaDiseaseHead2') }} </p>
                    <p class="text-xl mobile:text-lg text-[#999999]">{{ $t('service.retinaDiseaseSub2') }} </p>
                    <p class="mt-4  font-light mobile:text-sm">{{ $t('service.retinaDiseaseDetail2') }} </p>
                </div>
                <div ref="retinaDisease3" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 2 && Check }"  class="my-6 snap-start p-3">
                    <p class="text-xl mobile:text-lg ">{{ $t('service.retinaDiseaseHead3') }} </p>
                    <p class="text-xl mobile:text-lg text-[#999999]">{{ $t('service.retinaDiseaseSub3') }} </p>
                    <p class="mt-4  font-light mobile:text-sm">{{ $t('service.retinaDiseaseDetail3') }} </p>
                </div>
                <div ref="retinaDisease4" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 3 && Check }"  class="my-6 snap-start p-3 ">
                    <p class="text-xl mobile:text-lg ">{{ $t('service.retinaDiseaseHead4') }} </p>
                    <p class="text-xl mobile:text-lg text-[#999999]">{{ $t('service.retinaDiseaseSub4') }} </p>
                    <p class="mt-4  font-light mobile:text-sm">{{ $t('service.retinaDiseaseDetail4') }} </p>
                </div>
                <div ref="retinaDisease5" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 4 && Check }"  class="my-6 snap-start  p-3 ">
                    <p class="text-xl mobile:text-lg ">{{ $t('service.retinaDiseaseHead5') }} </p>
                    <p class="text-xl mobile:text-lg text-[#999999]">{{ $t('service.retinaDiseaseSub5') }} </p>
                    <p class="my-4  font-light mobile:text-sm">{{ $t('service.retinaDiseaseDetail5') }} </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// import crossIcon from '../assets/TrueEye/icon/cross.png'
import { ref, onMounted, defineProps } from 'vue'
const retinaDisease1 = ref(null)
const retinaDisease2 = ref(null)
const retinaDisease3 = ref(null)
const retinaDisease4 = ref(null)
const retinaDisease5 = ref(null)
const container = ref(null)
const Check = ref(true)
const retinaDisease = [retinaDisease1, retinaDisease2, retinaDisease3, retinaDisease4, retinaDisease5]

const props = defineProps(['scrollTarget'])
onMounted(() => {
  const target = props.scrollTarget
  retinaDisease[props.scrollTarget].value.scrollIntoView({
    behavior: 'smooth',
    block: 'start'

  })
  setTimeout(() => {
    if (container.value) {
      container.value.addEventListener('scroll', () => {
        Check.value = false
      })
    }
  }, 800)
  console.log('target to:', target)
  console.log('scrollTarget is:', props.scrollTarget)
})
</script>

<script>
export default {
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}

</script>
