<template>
    <div class="mt-16 w-screen " :key="$i18n.locale">
        <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10">
            <img :src="trueEyeIcon" class="h-[85px]" alt="">
            <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('contact.header') }}</span>
        </div>
        <div class="w-full mt-16">
            <div class="w-full h-[22rem] mobile:h-auto bg-[#E6E6E6] flex items-center mobile:flex-col">
                <div v-if="compState.isMobile" class="flex justify-center w-full mobile:mt-8">
                    <div class="w-[90%] flex gap-5 items-center">
                        <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                        <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('contact.header') }}</span>
                    </div>
                </div>
                <div class="w-full h-[15rem] mobile:h-auto flex gap-x-5 mobile:gap-8 mobile:mt-8 mobile:flex-col">
                    <div class="w-2/3 mobile:w-full mobile:pr-0 h-full flex gap-x-12 pr-10 mobile:flex-col ">
                        <div class="w-1/2 mobile:order-1 mobile:w-full mobile:flex mobile:justify-center max-w-[450px] mobile:max-w-none h-full pl-10 mobile:pl-0 mobile:mt-8">
                            <iframe class="w-full mobile:w-[22.5rem] h-[15rem]" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/search?q=promphan%202-3&key=AIzaSyCA54YWKNx0JgTa_3N1yEk-0MW912yHJ0o"></iframe>
                        </div>
                        <div class="w-1/2 mobile:w-full h-full mobile:order-0 mobile:px-4">
                            <span class="font-medium text-2xl mobile:text-lg text-[#CC0006]">{{ $t('contact.companyName') }}</span>
                            <p class="mt-5 font-light text-base text-[#CC0006]">{{ $t('contact.location') }}</p>
                            <div class="mt-6 flex gap-3 items-center">
                                <img class="h-5 w-5" :src="phoneIcon" alt="">
                                <span class="font-light text-base text-[#CC0006]">{{ $t('contact.phone') }}</span>
                            </div>
                            <div class="mt-6 flex gap-3 items-center">
                                <img class="h-5 w-5" :src="mailIcon" alt="">
                                <span @click="onOpenMail" class="font-light text-base text-[#CC0006]">{{ $t('contact.mail') }}</span>
                            </div>
                            <div class="mt-6 flex gap-5 mobile:gap-10 items-center">
                                <img class="h-5 w-5" :src="fbIcon" alt="">
                                <a href="https://www.instagram.com/trueeye.ai/"><img class="h-5 w-5" :src="igIcon" alt=""></a>
                                <img class="h-5 w-5" :src="twIcon" alt="">
                                <img class="h-5 w-5" :src="lineIcon" alt="">
                            </div>
                        </div>
                    </div>
                    <div class=" w-1/3 mobile:w-full h-full pr-10 mobile:pr-0 flex justify-end mobile:justify-center">
                        <div class="w-[90%] mobile:w-[22.5rem] max-w-[425px] flex flex-col gap-4 justify-end">
                            <input type="email" v-model="compState.yourEmail" class="w-full h-[2rem] rounded-[10px] bg-white font-light text-base text-[#B3B3B3] pl-5" placeholder="Your email">
                            <textarea name="" id="" v-model="compState.yourMessage" class="w-full rounded-[10px] h-[7rem] bg-white font-light text-base pl-5 pt-2 text-[#B3B3B3] resize-none"></textarea>
                            <div class="w-full flex justify-end">
                                <div @click="onOpenMail" class="w-24 h-10 bg-white rounded-[10px] flex justify-center gap-3 items-center">
                                    <span class="text-[#B3B3B3] text-base font-light">{{ $t('contact.sendEmail') }}</span>
                                    <img class="w-5 h-5" :src="sendIcon" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="compState.isMobile" class="w-full h-full flex justify-center items-center mobile:pt-10 mobile:pb-5">
                    <div @click="scrollToTop" class="relative hover:cursor-pointer mt-8">
                        <div @mouseover="()=> compState.isBackToTopHover = true" @mouseout="() => compState.isBackToTopHover = false" class="w-full h-full absolute z-20"></div>
                        <div class="flex justify-center">
                            <img class="w-5 mobile:w-8 h-5 mobile:h-8" :src="backToTopIcon" alt="">
                            <transition name="fade">
                            <img v-if="compState.isBackToTopHover" class="w-5 mobile:w-8 mobile:h-8 h-5 absolute top-0" :src="backToTopHoverIcon" alt="">
                            </transition>
                        </div>
                        <span class="text-[#CCCCCC] font-light text-base">{{ $t('contact.backToTop') }}</span>
                    </div>
                </div>
            </div>
            <div v-if="!compState.isMobile" class="w-full h-[10rem] relative">
                <div class="w-full h-[60%] flex justify-center">
                    <div class="h-full w-[80%] flex justify-between items-end">
                        <div class=" pb-4">
                            <span class=" font-light text-black text-xs">©</span>
                            <span class=" font-light text-trueeye-red text-xs"> {{ $t('contact.copyRight') }} </span>
                        </div>
                        <div>
                            <img class="w-[176px] h-[69px]" :src="trueEyeFooterIcon" alt="">
                        </div>
                    </div>
                </div>
                <div class="absolute w-full h-full top-0 flex justify-center items-center">
                    <div @click="scrollToTop" class="relative hover:cursor-pointer mt-8">
                        <div @mouseover="()=> compState.isBackToTopHover = true" @mouseout="() => compState.isBackToTopHover = false" class="w-full h-full absolute z-20"></div>
                        <div class="flex justify-center">
                            <img class="w-5 h-5" :src="backToTopIcon" alt="">
                            <transition name="fade">
                                <img v-if="compState.isBackToTopHover" class="w-5 h-5 absolute top-0" :src="backToTopHoverIcon" alt="">
                            </transition>
                        </div>
                        <span class="text-[#CCCCCC] font-light text-base">{{ $t('contact.backToTop') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useMediaQuery } from '@vueuse/core'

import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'
import trueEyeFooterIcon from '../assets/TrueEye/Logo/Logo on footer.png'
import sendIcon from '../assets/TrueEye/icon/send1.png'
import backToTopIcon from '../assets/TrueEye/icon/arrow-variant.png'
import backToTopHoverIcon from '../assets/TrueEye/icon/arrow 3.png'
import phoneIcon from '../assets/TrueEye/icon/phone.png'
import mailIcon from '../assets/TrueEye/icon/mail.png'
import fbIcon from '../assets/TrueEye/icon/fb.png'
import igIcon from '../assets/TrueEye/icon/ig.png'
import twIcon from '../assets/TrueEye/icon/TWT.png'
import lineIcon from '../assets/TrueEye/icon/LINE.png'

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  isBackToTopHover: false,
  yourEmail: '',
  yourMessage: 'Your message'
})
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
const onOpenMail = () => {
  console.log(compState.yourEmail, compState.yourMessage);
  (compState.yourEmail && compState.yourMessage)
    ? window.location.href = `mailto:info@trueeye.ai?subject=Email Subject&body=${compState.yourMessage}`
    : window.location.href = 'mailto:info@trueeye.ai?subject=Email Subject&body=Email Body'
}
</script>

<style>

</style>
