<template>
  <transition name="fade">
    <div v-if="isShowModal" class="fixed w-screen h-screen top-0 left-0 z-30 flex justify-center items-center"
      :key="$i18n.locale">
      <div @click="closeModal" class="absolute top-0 left-0 w-full h-full bg-black opacity-80"></div>
      <div class="w-[50%] mobile:w-[90%]">
        <!-- Main Swiper -->
        <Swiper :loop="true" :spaceBetween="10" :navigation="false" :thumbs="{ swiper: thumbsSwiper }"
          :initial-slide="actIMGIndex[0]" :modules="[FreeMode, Navigation, Thumbs]" @swiper="onSwiper" class="h-[50%]">
          <!-- <div v-if="!compState.isMobile" @click="closeModal"
            class="absolute top-4 right-4 w-[40px] h-[40px] mobile:w-[32px] mobile:h-[32px] rounded-full bg-white opacity-50 flex justify-center items-center z-10 hover:cursor-pointer">
            <img class="w-[50%] h-[50%]" :src="crossIcon" alt="" />
          </div> -->
          <div class="absolute top-0 bottom-0 mobile:top-5 left-4 right-4 flex justify-between items-center">
            <div @click="onBack" class="w-[40px] h-[40px] z-10 hover:cursor-pointer opacity-50">
              <img class="w-full h-full" :src="backIcon" alt="" />
            </div>
            <div @click="onNext" class="w-[40px] h-[40px] z-10 hover:cursor-pointer opacity-50">
              <img class="w-full h-full" :src="nextIcon" alt="" />
            </div>
          </div>
          <div v-if="!compState.isMobile"
            class="absolute bottom-0 left-0 right-0 bg-black z-10 bg-opacity-70 text-white text-center text-sm font-light p-4">
            {{ $t('activity.actDetail' + [actIMGIndex]) }}
          </div>

          <SwiperSlide v-for="(item, index) in actIMGList[actIMGIndex]" :key="index">
            <img class="w-full" :src="item" alt="" />
          </SwiperSlide>
        </Swiper>

        <!-- Thumbnail Swiper -->
        <Swiper :onSwiper="setThumbsSwiper" :loop="true" :spaceBetween="50" :slidesPerView="3" :freeMode="true"
          :watchSlidesProgress="true" :initial-slide="actIMGIndex" :modules="[FreeMode, Navigation, Thumbs]" class="mt-5"
          v-if="!compState.isMobile">
          <SwiperSlide v-for="(item, index) in actIMGList[actIMGIndex]" :key="index">
            <img class="w-full h-full" :src="item" alt="" @click="changeMainImage(index)" />
          </SwiperSlide>
        </Swiper>

      <div v-if="compState.isMobile"
        class="z-10 opacity-90 text-white text-center text-sm font-light p-4">
        {{ $t('activity.actDetail' + [actIMGIndex]) }}
      </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { FreeMode, Navigation, Thumbs } from 'swiper/core'
import { useMediaQuery } from '@vueuse/core'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import backIcon from '../assets/TrueEye/icon/arrow 7.png'
import nextIcon from '../assets/TrueEye/icon/arrow 6.png'
// import crossIcon from '../assets/TrueEye/icon/cross.png'

const store = useStore()

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  swiper: {},
  thumbsSwiper: {} // Added thumbsSwiper to compState
})

const isShowModal = computed(() => store.getters['compState/getIsShowModal'])
const actIMGList = computed(() => store.getters['compState/getActIMGList'])
const actIMGIndex = computed(() => store.getters['compState/getActIMGIndex'])

const onSwiper = (swiper) => {
  compState.swiper = swiper
}

const setThumbsSwiper = (swiper) => {
  compState.thumbsSwiper = swiper
}

const closeModal = () => {
  store.commit('compState/setIsShowModal', false)
  document.body.classList.remove('modal-open')
}

const onNext = () => {
  compState.swiper.slideNext()
}

const onBack = () => {
  compState.swiper.slidePrev()
}

const changeMainImage = (index) => {
  compState.swiper.slideTo(index)
  console.log(index)
}
</script>

<style>
/* Your styles go here */
</style>
