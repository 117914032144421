<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80 z-10" @click="onClose">
    <div class="bg-white rounded-lg shadow w-[60%] mobile:w-[80%]">
      <div class="flex items-start justify-between p-5 border-b rounded-t">
        <span class="font-light text-trueeye-red text-[40px] mobile:text-xl w-[80%]">{{ $t('service.questionService') }}</span>
        <!-- <div class=" w-[30px] h-[30px] shadow-md rounded-full opacity-50 flex justify-center items-center z-10 hover:cursor-pointer "
             @click.stop="onClose">
          <img class="w-[50%] h-[50%]" :src="crossIcon" alt="">
        </div> -->
      </div>
      <div ref="container"  class="px-6 snap-proximity space-y h-[50vh] mobile:h-[65vh] overflow-y-auto " @click.stop>
        <div ref="questionDisease1" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 0 && Check}" class="my-6 snap-start p-3 ">
          <p class="text-xl mobile:text-lg">{{ $t('service.questionDiseaseHead1') }} </p>
          <p class="mt-4 font-light mobile:text-sm">{{ $t('service.questionDiseaseDetail1') }} </p>
        </div>
        <div ref="questionDisease2" :class="{ 'bg-[#E6E6E6] ': props.scrollTarget === 1 && Check}" class="my-6 snap-start p-3 ">
          <p class="text-xl  mobile:text-lg">{{ $t('service.questionDiseaseHead2') }} </p>
          <p class="my-4 font-light mobile:text-sm">{{ $t('service.questionDiseaseDetail2') }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import crossIcon from '../assets/TrueEye/icon/cross.png'
import { ref, onMounted, defineProps } from 'vue'

const questionDisease1 = ref(null)
const questionDisease2 = ref(null)
const container = ref(null)
const Check = ref(true)
const questionDisease = [questionDisease1, questionDisease2]

const props = defineProps(['scrollTarget'])
onMounted(() => {
  questionDisease[props.scrollTarget].value.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
  setTimeout(() => {
    if (container.value) {
      container.value.addEventListener('scroll', () => {
        Check.value = false
      })
    }
  }, 600)
})

</script>

<script>
export default {
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
