<template>
<swiper
    v-if="!compState.isMobile"
    class="w-[70%] relative"
    :loop="true"
    :slides-per-view="1"
    :space-between="0"
    :allow-touch-move="false"
    @slideChange="onSlideChange"
    @init="onSlideInit"
    @swiper="onSwiper"
>
    <swiper-slide class="w-[60rem] pt-10">
        <div class="w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[0]" target="_blank"><img class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg1" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[0]" target="_blank"><span class=" font-medium text-lg  text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader1') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate1') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail1') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2 " :src="newsIconTP" alt="">
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-[5rem] w-full h-[18rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[1]" target="_blank"><img class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg2" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[1]" target="_blank"><span  class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader2') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate2') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail2') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconCU" alt="">
                    </p>
                </div>
            </div>
        </div>
    </swiper-slide>
    <swiper-slide class="w-[60rem]">
        <div class="w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[2]" target="_blank"><img  class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg3" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[2]" target="_blank"><span class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader3') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate3') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail3') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconBT" alt="">
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconCH7" alt="">
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconTD" alt="">
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconTE" alt="">
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-[5rem] w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[3]" target="_blank"><img  class="h-[214px] w-[388px]" :src="newsImg4" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[3]" target="_blank"><span class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader4') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate4') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail4') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconTR" alt="">
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconPPTV" alt="">
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconMGR" alt="">
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconTNA" alt="">
                    </p>
                </div>
            </div>
        </div>
    </swiper-slide>
    <!-- ADD News Contents -->
    <swiper-slide class="w-[60rem]">
        <div class="w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[4]" target="_blank"><img  class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg5" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[4]" target="_blank"><span class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader5') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate5') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail5') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconPBS" alt="">
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-[5rem] w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[5]" target="_blank"><img  class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg6" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[5]" target="_blank"><span class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader6') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate6') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail6') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconTS" alt="">
                    </p>
                </div>
            </div>
        </div>
    </swiper-slide>

    <swiper-slide class="w-[60rem]">
        <div class="w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[6]" target="_blank"><img  class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg7" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[6]" target="_blank"><span class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader7') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate7') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail7') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconBiz" alt="">
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-[5rem] w-full h-[16rem] flex items-center justify-center">
            <div class="w-[45%] h-full">
                <a :href="newsLinks[7]" target="_blank"><img  class="h-[214px] w-[388px] hover:cursor-pointer" :src="newsImg8" alt=""></a>
            </div>
            <div class="w-1/2 h-full">
                <div class="w-full h-full -mt-2">
                    <a :href="newsLinks[7]" target="_blank"><span class=" font-medium text-lg text-trueeye-red hover:cursor-pointer">{{ $t('news.newsHeader8') }}</span></a>
                    <p class=" mt-4 font-light text-nct text-black">{{ $t('news.newsDate8') }}</p>
                    <p class="mt-2 font-light text-nct text-black">{{ $t('news.newsDetail8') }}</p>
                    <p class="mt-4 font-light text-nct flex items-center text-black">{{ $t('news.newsRef') }}
                        <img class="h-[36px] w-[36px] mx-2" :src="newsIconUTC" alt="">
                    </p>
                </div>
            </div>
        </div>
    </swiper-slide>
    <!-- ----------------- -->
    <div class="absolute right-0 bottom-0 flex gap-2">
        <div @click="onBack" class=" w-[30px] h-[30px] z-10 hover:cursor-pointer opacity-75">
            <img class="w-full h-full" :src="backIcon" alt="">
        </div>
        <span class="font-medium text-black text-lg"> {{ `${compState.newsPageIndex+1 }/${compState.newsPageLength}` }}</span>
        <div @click="onNext" class=" w-[30px] h-[30px] z-10 hover:cursor-pointer opacity-75">
            <img class="w-full h-full" :src="nextIcon" alt="">
        </div>
    </div>
</swiper>
<div v-if="compState.isMobile" class="mobile:w-full mobile:flex mobile:gap-x-6 mobile:gap-y-8 mobile:justify-center mobile:flex-wrap">
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[0]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg1" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate1') }}</p>
        <a :href="newsLinks[0]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm mobile:text-black">{{ $t('news.newsHeader1') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[1]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg2" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate2') }}</p>
        <a :href="newsLinks[1]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader2') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[2]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg3" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate3') }}</p>
        <a :href="newsLinks[2]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader3') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[3]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg4" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate4') }}</p>
        <a :href="newsLinks[3]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader4') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[4]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg5" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate5') }}</p>
        <a :href="newsLinks[4]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader5') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[5]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg6" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate6') }}</p>
        <a :href="newsLinks[5]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader6') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[6]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg7" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate7') }}</p>
        <a :href="newsLinks[6]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader7') }}</p></a>
    </div>
    <div class="mobile:w-[168px]">
        <a :href="newsLinks[7]" target="_blank"><img  class="mobile:h-[96px] mobile:w-[168px] hover:cursor-pointer" :src="newsImg8" alt=""></a>
        <p class=" mobile:mt-5 mobile:font-light mobile:text-sm mobile:text-[#CC0006]">{{ $t('news.newsDate8') }}</p>
        <a :href="newsLinks[7]" target="_blank"><p class="mobile:mt-2 mobile:font-light mobile:text-sm  mobile:text-black">{{ $t('news.newsHeader8') }}</p></a>
    </div>
</div>
</template>

<script setup>
import { reactive } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useMediaQuery } from '@vueuse/core'

import backIcon from '../assets/TrueEye/icon/arrow 7.png'
import nextIcon from '../assets/TrueEye/icon/arrow 6.png'

import newsImg1 from '../assets/TrueEye/News/News_Tpbs_2_resize.jpg'
import newsImg2 from '../assets/TrueEye/News/News_Chula.png'
import newsImg3 from '../assets/TrueEye/News/News_BT_resize.jpg'
import newsImg4 from '../assets/TrueEye/News/News_PM.png'
import newsImg5 from '../assets/TrueEye/News/News_TPBS_1_resize.jpg'
import newsImg6 from '../assets/TrueEye/News/Techsauce.png'
import newsImg7 from '../assets/TrueEye/News/Bangkokbiz.png'
import newsImg8 from '../assets/TrueEye/News/News_UTC.png'

import newsIconBT from '../assets/TrueEye/LogoNews/Beartai.jpeg'
import newsIconBiz from '../assets/TrueEye/LogoNews/BKKBiz.png'
import newsIconCH7 from '../assets/TrueEye/LogoNews/CH7HD.jpeg'
import newsIconCU from '../assets/TrueEye/LogoNews/Chula.jpeg'
import newsIconUTC from '../assets/TrueEye/LogoNews/CU_UTC.png'
import newsIconMGR from '../assets/TrueEye/LogoNews/MGRonline.jpeg'
import newsIconPPTV from '../assets/TrueEye/LogoNews/PPTV.jpeg'
import newsIconTS from '../assets/TrueEye/LogoNews/Techsauce.png'
import newsIconTD from '../assets/TrueEye/LogoNews/TeroDigital.jpeg'
import newsIconTE from '../assets/TrueEye/LogoNews/TeroEntertainment.png'
import newsIconTNA from '../assets/TrueEye/LogoNews/ThaiNewsAgency.png'
import newsIconPBS from '../assets/TrueEye/LogoNews/ThaiPBS.png'
import newsIconTP from '../assets/TrueEye/LogoNews/Thaipost.jpeg'
import newsIconTR from '../assets/TrueEye/LogoNews/Thairath.png'

const newsLinks = [
  'https://www.thaipost.net/news-update/297894/',
  'https://www.tiktok.com/@chulalongkornuniversity/video/7205438633887714562',
  'https://www.youtube.com/watch?v=DFW1I_SVJWY',
  'https://www.thairath.co.th/news/politic/2563593',
  'https://www.youtube.com/watch?v=TTGk6G8YDcg',
  'https://techsauce.co/pr-news/nia-true-eye-technology',
  'https://www.bangkokbiznews.com/lifestyle/926802',
  'https://www.facebook.com/ChulaUTC/videos/%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%AA%E0%B8%B3%E0%B8%84%E0%B8%B1%E0%B8%8D%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%AA%E0%B8%B8%E0%B8%94%E0%B9%80%E0%B8%A5%E0%B8%A2-%E0%B8%84%E0%B8%B7%E0%B8%AD-utc-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B9%80%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%A1%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%9C%E0%B8%B9%E0%B9%89%E0%B9%80%E0%B8%8A%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%8A%E0%B8%B2%E0%B8%8D%E0%B9%83%E0%B8%99%E0%B8%AA%E0%B8%B2%E0%B8%82%E0%B8%B2%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%A7%E0%B8%B4%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B2%E0%B8%B0%E0%B8%AB%E0%B9%8C/485539909461387/ '

]
const onSlideInit = (swiper) => {
  compState.newsPageLength = swiper.slides.length
  compState.newsPageIndex = swiper.realIndex
}
const onSlideChange = (swiper) => {
  compState.newsPageIndex = swiper.realIndex
}
const onSwiper = (swiper) => {
  compState.swiper = swiper
}
const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  swiper: {},
  newsPageIndex: 1,
  newsPageLength: 1
})
const onNext = () => {
  compState.swiper.slideNext()
}
const onBack = () => {
  compState.swiper.slidePrev()
}
</script>
