<template>
  <div class="grid grid-cols-3 gap-14 w-[80%] mobile:w-full mobile:flex mobile:gap-x-6 mobile:gap-y-8 mobile:justify-center mobile:flex-wrap">
    <div v-for="(item, index) in actIMGList" :key="index" class="">
      <img
        @click="() => onIMGClick(index)"
        class="w-[100%] h-[70%] mobile:h-[96px] mobile:w-[168px] object-cover hover:cursor-pointer"
        :src=item[0]
        alt=""
      />
      <div v-if="!compState.isMobile">
      <p class="mt-4 font-medium text-lg text-trueeye-red">{{ $t('activity.actName'+[index]) }}</p>
      <p class="font-light text-base text-black">{{ $t('activity.actDate'+[index]) }}</p>
    </div>
    <div v-if="compState.isMobile">
      <p class="mobile:mt-5 mobile:font-light  mobile:w-[168px] mobile:text-sm mobile:text-[#CC0006]">{{ $t('activity.actDate'+[index]) }}</p>
      <p class="mobile:mt-2 mobile:font-light  mobile:w-[168px] mobile:text-sm mobile:text-black">{{ $t('activity.actName'+[index]) }}</p>
    </div>
  </div>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useMediaQuery } from '@vueuse/core'

import actIMGsmt0 from '../assets/TrueEye/Activity/Samitivej_2.png'
import actIMGsmt1 from '../assets/TrueEye/Activity/Samitivej_1.png'
import actIMGsmt2 from '../assets/TrueEye/Activity/Samitivej_3.png'
import actIMGenp0 from '../assets/TrueEye/Activity/ENPY_1.jpg'
import actIMGenp1 from '../assets/TrueEye/Activity/ENPY_2.jpg'
import actIMGenp2 from '../assets/TrueEye/Activity/ENPY_3.jpg'
import actIMGdhf0 from '../assets/TrueEye/Activity/Digital_health_forum_1.jpg'
import actIMGdhf1 from '../assets/TrueEye/Activity/Digital_health_forum_2.jpg'
import actIMGdhf2 from '../assets/TrueEye/Activity/Digital_health_forum_3.jpg'
import actIMGpcs0 from '../assets/TrueEye/Activity/PCSHS_1.jpg'
import actIMGpcs1 from '../assets/TrueEye/Activity/PCSHS_2.jpg'
import actIMGpcs2 from '../assets/TrueEye/Activity/PCSHS_3.jpg'
import actIMGpbs0 from '../assets/TrueEye/Activity/Thai_PBS_1.jpeg'
import actIMGpbs1 from '../assets/TrueEye/Activity/Thai_PBS_2.jpeg'
import actIMGpbs2 from '../assets/TrueEye/Activity/Thai_PBS_3.jpeg'
import actIMGsyn0 from '../assets/TrueEye/Activity/Synbio_consortium_1.jpg'
import actIMGsyn1 from '../assets/TrueEye/Activity/Synbio_consortium_2.jpg'
import actIMGsyn2 from '../assets/TrueEye/Activity/Synbio_consortium_3.jpg'

const store = useStore()

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  swiperMain: {}
})

const actIMGList = [
  [actIMGpcs0, actIMGpcs1, actIMGpcs2],
  [actIMGsyn0, actIMGsyn1, actIMGsyn2],
  [actIMGenp0, actIMGenp1, actIMGenp2],
  [actIMGdhf0, actIMGdhf1, actIMGdhf2],
  [actIMGpbs0, actIMGpbs1, actIMGpbs2],
  [actIMGsmt0, actIMGsmt1, actIMGsmt2]]

onMounted(() => {
  store.commit('compState/setActIMGList', actIMGList)
})

const onIMGClick = (index) => {
  store.dispatch('compState/openActivityModal', {
    isShowModal: true,
    actIMGIndex: index
  })
  document.body.classList.add('modal-open')
  console.log(store.getters['compState/getIsShowModal'], store.getters['compState/getActIMGIndex'])
  console.log(index)
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
body.modal-open {
  overflow: hidden;
}
</style>
