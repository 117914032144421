<template>
    <div class="mt-32 mobile:mt-24 relative w-screen  pb-[10rem] mobile:pb-0" :key="$i18n.locale">
        <div v-if="!compState.isMobile" class="absolute w-full h-full -z-10">
            <div class="relative w-full h-full overflow-x-hidden">
                <img class=" absolute -right-1 top-[3.5rem] h-[347px] w-[169px] opacity-20" :src="rightBG" alt="">
                <img class=" absolute -left-1 bottom-[5rem] h-[442px] w-[211px] opacity-20" :src="leftBG" alt="">
            </div>
        </div>
        <div v-if="compState.isMobile" class="flex justify-center w-full">
            <div class="w-[90%] flex gap-5 items-center">
                <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('news.header') }}</span>
            </div>
        </div>
        <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10">
            <img :src="trueEyeIcon" class="h-[85px]" alt="">
            <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('news.header') }}</span>
        </div>
        <div class="w-full mt-[2.5rem] flex justify-center">
            <NewsSlide />
        </div>
        <div class="w-full mt-[8rem] mobile:mt-16">
            <div v-if="compState.isMobile" class="flex justify-center w-full">
                <div class="w-[90%] flex gap-5 items-center">
                    <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                    <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('news.activityHeader')
                    }}</span>
                </div>
            </div>
            <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10">
                <img :src="trueEyeIcon" class="h-[85px]" alt="">
                <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('news.activityHeader') }}</span>
            </div>

            <!-- Edit Mobile Layout -->
            <div class="w-full mt-[3rem] flex justify-center">
                <ActivitySlide />
            </div>
            <!-- -------------------- -->

        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'

import NewsSlide from './NewsSilde.vue'
import ActivitySlide from './ActivitySlide.vue'
import rightBG from '../assets/TrueEye/Graphics/Graphic 3.png'
import leftBG from '../assets/TrueEye/Graphics/Graphic 4.png'

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)')
})

</script>
