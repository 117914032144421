<template>
    <div class="w-screen  relative mt-[8rem] mobile:mt-16" :key="$i18n.locale">
        <div v-if="!compState.isMobile" class="absolute w-full h-full -z-10">
            <div class="relative w-full h-full overflow-hidden opacity-20">
                <img class=" absolute -right-1 top-[48px] h-[347px] w-[169px]" :src="rightBG" alt="">
                <img class=" absolute -left-1 top-[620px] h-[442px] w-[211px]" :src="leftBG" alt="">
            </div>
        </div>
        <div class="flex justify-center w-full">
            <div v-if="compState.isMobile" class="flex justify-center w-full">
                <div class="w-[90%] flex gap-5 items-center">
                    <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                    <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('service.header')
                    }}</span>
                </div>
            </div>
            <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10 w-full">
                <img :src="trueEyeIcon" class="h-[85px]" alt="">
                <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('service.header') }}</span>
            </div>
        </div>

        <!-- EDIT EYE Sevice Content  -->
        <div v-if="!compState.isMobile">
            <div class="w-full mt-13 px-8 mobile:mt-[6rem]">
                <div class="w-full flex justify-center">
                    <div class="flex flex-col gap-5 items-center ">
                        <img class=" h-[150px] w-[150px] " :src="retinaScreeningIcon" alt="">
                        <span class=" text-xl text-trueeye-red">{{ $t('service.retinaService') }}</span>
                    </div>
                </div>
                <div class="mt-12 grid grid-cols-12 gap-4 gap-y-12">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="cvdIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 text-xl">
                        <p class="text-lg">{{ $t('service.retinaDiseaseHead1') }} </p>
                        <p class=" text-[#999999]">{{ $t('service.retinaDiseaseSub1') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  text-base">{{ $t('service.retinaDiseaseSubDetail1') }}</div>
                    <div class="col-start-11 col-span-2">
                        <!-- ~~ -->
                        <button @click="onPopup1(0)"
                            class="bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>

                <div class="mt-8 grid grid-cols-12 gap-4 gap-y-12 ">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="ckdIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 text-xl">
                        <p class="text-lg">{{ $t('service.retinaDiseaseHead2') }} </p>
                        <p class=" text-[#999999]">{{ $t('service.retinaDiseaseSub2') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  text-base"> {{ $t('service.retinaDiseaseSubDetail2') }} </div>
                    <div class="col-start-11 col-span-2">
                        <button @click="onPopup1(1)"
                            class="bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>

                <div class="mt-8 grid grid-cols-12 gap-4 gap-y-12">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="yrsIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 text-xl">
                        <p class="text-lg">{{ $t('service.retinaDiseaseHead3') }} </p>
                        <p class=" text-[#999999]">{{ $t('service.retinaDiseaseSub3') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  text-base"> {{ $t('service.retinaDiseaseSubDetail3') }} </div>
                    <div class="col-start-11 col-span-2">
                        <button @click="onPopup1(2)"
                            class="bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>

                <div class="mt-8 grid grid-cols-12 gap-4 gap-y-12">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="dmIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 text-xl">
                        <p class="text-lg">{{ $t('service.retinaDiseaseHead4') }} </p>
                        <p class=" text-[#999999]">{{ $t('service.retinaDiseaseSub4') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  text-base"> {{ $t('service.retinaDiseaseSubDetail4') }} </div>
                    <div class="col-start-11 col-span-2">
                        <button @click="onPopup1(3)"
                            class="bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>

                <div class="mt-8 grid grid-cols-12 gap-4 gap-y-12">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="drIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 text-xl">
                        <p class="text-lg">{{ $t('service.retinaDiseaseHead5') }} </p>
                        <p class=" text-[#999999]">{{ $t('service.retinaDiseaseSub5') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  text-base"> {{ $t('service.retinaDiseaseSubDetail5') }} </div>
                    <div class="col-start-11 col-span-2">
                        <button @click="onPopup1(4)"
                            class="bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>
                <!-- ------------------- -->

                <!-- EDIT Question Sevice Content -->
                <div class=" mt-16 w-full flex justify-center">
                    <div class="flex flex-col gap-5 items-center ">
                        <img class=" h-[150px] w-[150px] " :src="questionnairesIcon" alt="">
                        <span class=" text-xl text-trueeye-red">{{ $t('service.questionService') }}</span>
                    </div>
                </div>
                <div class="mt-12 grid grid-cols-12 gap-4 gap-y-12">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="mpIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 mt-4">
                        <p class="text-lg">{{ $t('service.questionDiseaseHead1') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  mt-4 text-base"> {{ $t('service.questionDiseaseSubDetail1') }}
                    </div>
                    <div class="col-start-11 col-span-2 ">
                        <button @click="onPopup2(0)"
                            class=" mt-4 bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>

                <div class="mt-8 grid grid-cols-12 gap-4 gap-y-12">
                    <div class="col-start-2 col-span-1">
                        <img class=" h-[56px] w-[56px] " :src="dpIcon" alt="">
                    </div>
                    <div class="col-start-3 col-span-3 mt-4">
                        <p class="text-lg">{{ $t('service.questionDiseaseHead2') }} </p>
                    </div>
                    <div class="col-start-6 col-span-5  mt-4 text-base "> {{ $t('service.questionDiseaseSubDetail2') }}
                    </div>
                    <div class="col-start-11 col-span-2">
                        <button @click="onPopup2(1)"
                            class=" mt-4 bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                </div>
                <!-- ----------------------- -->
            </div>
        </div>

        <!-- Edit Eye Service Content For Mobile -->
        <div v-if="compState.isMobile">
            <div class="w-full mt-16">
                <div class="w-full flex justify-center">
                    <div class="flex flex-col gap-5 items-center ">
                        <img class=" h-[150px] w-[150px]" :src="retinaScreeningIcon" alt="">
                        <span class=" text-lg text-trueeye-red">{{ $t('service.retinaService') }}</span>
                    </div>
                </div>
                <div class="mt-3 container mx-auto overflow-y-auto h-[350px] bg-[#E6E6E6]">
                    <div class="mt-3 center flex flex-col items-center text-center">
                        <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="cvdIcon" alt="">
                        <p class="">{{ $t('service.retinaDiseaseHead1') }} </p>
                        <p class="my-2 text-[#999999]">{{ $t('service.retinaDiseaseSub1') }} </p>
                        <div class="px-6 text-sm font-light"> {{ $t('service.retinaDiseaseSubDetail1') }} </div>
                        <button @click="onPopup1(0)"
                            class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                    <div class="mt-7 center flex flex-col items-center text-center">
                        <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="ckdIcon" alt="">
                        <p class="">{{ $t('service.retinaDiseaseHead2') }} </p>
                        <p class="my-2 text-[#999999]">{{ $t('service.retinaDiseaseSub2') }} </p>
                        <div class="px-6 text-sm font-light"> {{ $t('service.retinaDiseaseSubDetail2') }} </div>
                        <button @click="onPopup1(1)"
                            class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                    <div class="mt-7 center flex flex-col items-center text-center">
                        <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="yrsIcon" alt="">
                        <p class="">{{ $t('service.retinaDiseaseHead3') }} </p>
                        <p class="my-2 text-[#999999]">{{ $t('service.retinaDiseaseSub3') }} </p>
                        <div class="px-6 text-sm font-light"> {{ $t('service.retinaDiseaseSubDetail3') }} </div>
                        <button @click="onPopup1(2)"
                            class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                    <div class="mt-7 center flex flex-col items-center text-center">
                        <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="dmIcon" alt="">
                        <p class="">{{ $t('service.retinaDiseaseHead4') }} </p>
                        <p class="my-2 text-[#999999]">{{ $t('service.retinaDiseaseSub4') }} </p>
                        <div class="px-6 text-sm font-light"> {{ $t('service.retinaDiseaseSubDetail4') }} </div>
                        <button @click="onPopup1(3)"
                            class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                            Read more
                        </button>
                    </div>
                    <div class="my-7 center flex flex-col items-center text-center">
                        <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="drIcon" alt="">
                        <p class="">{{ $t('service.retinaDiseaseHead5') }} </p>
                        <p class="my-2 text-[#999999]">{{ $t('service.retinaDiseaseSub5') }} </p>
                        <div class="px-6 text-sm font-light"> {{ $t('service.retinaDiseaseSubDetail5') }} </div>
                        <button @click="onPopup1(4)"
                            class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                            Read more
                        </button>
                    </div>

                </div>
            </div>
            <!-- ----------------------- -->

            <!-- EDIT Question Sevice Content For Mobile -->
            <div class="mt-16 w-full flex justify-center">
                <div class="flex flex-col gap-5 items-center ">
                    <img class=" h-[150px] w-[150px]" :src="questionnairesIcon" alt="">
                    <span class=" text-lg  text-trueeye-red">{{ $t('service.questionService') }}</span>
                </div>
            </div>
            <div class="mt-3 container mx-auto overflow-y-auto h-[350px] bg-[#E6E6E6]">
                <div class="mt-3 center flex flex-col items-center text-center">
                    <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="mpIcon" alt="">
                    <p>{{ $t('service.questionDiseaseHead1') }} </p>
                    <div class="px-6 text-sm font-light"> {{ $t('service.questionDiseaseSubDetail1') }} </div>
                    <button @click="onPopup2(0)"
                        class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                        Read more
                    </button>
                </div>
                <div class="my-7 center flex flex-col items-center text-center">
                    <img class=" h-[56px] w-[56px] mobile:h-[6.25rem] mobile:w-[6.25rem]" :src="dpIcon" alt="">
                    <p>{{ $t('service.questionDiseaseHead2') }} </p>
                    <div class="px-6 text-sm font-light"> {{ $t('service.questionDiseaseSubDetail2') }} </div>
                    <button @click="onPopup2(1)"
                        class="mt-3 text-[#999999] border-[#999999] hover:bg-gray-100  py-2 px-4 border rounded text-sm font-light">
                        Read more
                    </button>
                </div>
            </div>

        </div>
        <!-- ----------------------- -->

        <!-- EDIT Dashboard Sevice Content -->
        <div class="mt-16 w-full justify-center text-center">
            <div class="flex flex-col gap-5 items-center">
                <img class="h-[150px] w-[150px] " :src="dashbordIcon" alt="">
                <span class="text-xl mobile:text-lg  text-trueeye-red">{{ $t('service.dashboardService') }}</span>
            </div>
            <p class="mt-8 text-base text-center px-36 mobile:px-12 mobile:text-sm ">{{ $t('service.dashboardSubDetail') }}
            </p>
            <button @click="onPopup3()"
                class="mt-8 mx-3 bg-white hover:bg-gray-100 text-gray-400 py-2 px-4 border border-gray-400 rounded text-sm font-light">
                Read more
            </button>
        </div>
        <!-- ----------------------- -->
    </div>

    <!-- ADD Popup Content1&2  -->
    <ServiceEyePopup v-if="compState.isPopup1" @close="onPopup1()" :scroll-target="compState.retinaScrollTarget" />
    <ServiceQuesPopup v-if="compState.isPopup2" @close="onPopup2()" :scroll-target="compState.retinaScrollTarget" />

    <!-- ADD Popup Content3  -->
    <div v-if="compState.isPopup3"
        class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80 z-10" @click="onPopup3">
    <div class="bg-white rounded-lg shadow w-[60%] mobile:w-[80%]" >
      <div class="flex items-start justify-between p-5 border-b rounded-t">
        <span class="font-light text-trueeye-red text-[40px] mobile:text-xl w-[80%]">{{ $t('service.dashboardService') }}</span>
      </div>
            <div class="p-6 space-y-6 h-[50vh] mobile:h-[65vh] overflow-y-auto" @click.stop>
                <p class="whitespace-pre-line text-base mobile:text-sm">{{ $t('service.dashboardSubDetail') }}</p>
                <p class="whitespace-pre-line text-base mobile:text-sm">{{ $t('service.dashboardDetail') }}</p>
            </div>
        </div>
    </div>

    <!-- ----------------- -->
    <div class="flex justify-center w-full mt-[64px]">
        <div v-if="compState.isMobile" class="flex justify-center w-full">
            <div class="w-[90%] flex gap-5 items-center">
                <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('service.processHeader')
                }}</span>
            </div>
        </div>
        <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10 w-full">
            <img :src="trueEyeIcon" class="h-[85px]" alt="">
            <span class=" font-light text-trueeye-red text-[56px] mobile:text-[24px]">{{ $t('service.processHeader')
            }}</span>
        </div>
    </div>
    <div class="relative w-[70%] mobile:w-[90%] pb-[40%] mobile:pb-[53%] mx-auto mt-[56px]">
        <iframe class="absolute inset-0 w-full h-full" src="https://www.youtube.com/embed/gyFDNRPgwOo?si=PeT21o8gutHTtF37"
            frameborder="0" allowfullscreen></iframe>
    </div>
</template>

<script setup>
import { reactive, ref, onBeforeUnmount } from 'vue'
import { useMediaQuery } from '@vueuse/core'

import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'
import retinaScreeningIcon from '../assets/TrueEye/icon/eye.png'
import questionnairesIcon from '../assets/TrueEye/icon/questionnaire_icon.png'
import dashbordIcon from '../assets/TrueEye/icon/dashboard_icon.png'
import cvdIcon from '../assets/TrueEye/icon/cvd.png'
import ckdIcon from '../assets/TrueEye/icon/ckd.png'
import yrsIcon from '../assets/TrueEye/icon/5yrs.png'
import dmIcon from '../assets/TrueEye/icon/dm.png'
import drIcon from '../assets/TrueEye/icon/dr.png'

import mpIcon from '../assets/TrueEye/icon/mp.png'
import dpIcon from '../assets/TrueEye/icon/dp.png'

import rightBG from '../assets/TrueEye/Graphics/Graphic 3.png'
import leftBG from '../assets/TrueEye/Graphics/Graphic 4.png'
// import crossIcon from '../assets/TrueEye/icon/cross.png'

import ServiceEyePopup from './ServiceEyePop.vue'
import ServiceQuesPopup from './ServiceQuesPop.vue'
const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  isRetinaOpen: false,
  retinaCompHeight: 0,
  isSoundOpen: false,
  isPopup1: false,
  isPopup2: false,
  isPopup3: false,
  isMotionOpen: false,
  retinaScrollTarget: ''
})
const retinaComp = ref()

// onMounted(() => {
//   window.addEventListener('resize', handleOrientationChange)
//   compState.retinaCompHeight = retinaComp.value.clientHeight
// })

// onUpdated(() => {
//   compState.retinaCompHeight = retinaComp.value.clientHeight
// })
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOrientationChange)
})
// const retinaHeight = computed(() => {
//   return compState.retinaCompHeight
// })
// const voiceHeight = computed(() => {
//   return voiceComp.value.clientHeight
// })

const handleOrientationChange = () => {
  compState.retinaCompHeight = retinaComp.value.clientHeight
}

// ~~
const onPopup1 = (scrollTarget) => {
  compState.isPopup1 = !compState.isPopup1
  compState.retinaScrollTarget = scrollTarget
  document.body.classList.toggle('no-scroll', compState.isPopup1)
}

const onPopup2 = (scrollTarget) => {
  compState.isPopup2 = !compState.isPopup2
  compState.retinaScrollTarget = scrollTarget
  document.body.classList.toggle('no-scroll', compState.isPopup2)
}
const onPopup3 = () => {
  compState.isPopup3 = !compState.isPopup3
  document.body.classList.toggle('no-scroll', compState.isPopup3)
}

</script>
