<template>
    <div class="w-screen  mt-32 mobile:mt-24" :key="$i18n.locale">
        <div v-if="compState.isMobile" class="flex justify-center w-full">
            <div class="w-[90%] flex gap-5 items-center">
                <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('team.header') }}</span>
            </div>
        </div>
        <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10">
            <img :src="trueEyeIcon" class="h-[85px]" alt="">
            <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('team.header') }}</span>
        </div>
        <div v-if="!compState.isMobile" class="w-full flex justify-center mt-[40px]">
            <div class="w-[80%] text-center">
                <p class="text-trueeye-red font-medium text-xl">{{ $t('team.quotesMain') }}</p>
                <p class=" mt-6 font-medium text-lg">{{ $t('team.quotesSub') }}</p>
            </div>
        </div>
        <div v-if="compState.isMobile" class="w-full flex justify-center mt-[2.5rem]">
            <div class="px-7">
                <p class="text-trueeye-red font-medium text-lg text-center ">{{ $t('team.quotesMain') }}</p>
                <p class=" mt-6 text-[20px] text-center ">{{ $t('team.quotesSub') }}</p>
            </div>
        </div>
        <!-- ADD Team Contents -->
        <div class="mt-6 w-[50%] mx-auto mobile:w-[80%] text-center">

            <p class="mt-6 text-lg mobile:text-[20px] ">{{ $t('team.quotesTeam1') }}</p>
            <div class="text-base mx-3">
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub0') }}</p>
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub1') }}</p>
            </div>
            <p class="mt-6 text-lg mobile:text-[20px]">{{ $t('team.quotesTeam2') }}</p>
            <div class="font-light mx-3">
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub2') }}</p>
            </div>
            <p class="mt-6 text-lg mobile:text-[20px]">{{ $t('team.quotesTeam3') }}</p>
            <div class="font-light mx-3">
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub3') }}</p>
            </div>
            <p class="mt-6 text-lg mobile:text-[20px]">{{ $t('team.quotesTeam4') }}</p>
            <div class="font-light mx-3">
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub4') }}</p>
            </div>
            <p class="mt-6 text-lg mobile:text-[20px]">{{ $t('team.quotesTeam5') }}</p>
            <div class="font-light mx-3">
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub5') }}</p>
            </div>
            <p class="mt-6 text-lg mobile:text-[20px]">{{ $t('team.quotesTeam6') }}</p>
            <div class="font-light mx-3">
                <p class="mobile:text-sm">{{ $t('team.quotesTeamSub6') }}</p>
            </div>

        </div>
        <!-- ----------------- -->

        <!-- EDIT Co-Funding Team -->
        <div class="px-40 mobile:px-5 mt-6 ">
            <span class=" font-light text-trueeye-red  mobile:text-[24px] text-[40px] leading-[84px]">{{
                $t('team.subHeader1') }}</span>
        </div>
        <div v-if="!compState.isMobile">
            <div class="w-full flex justify-center gap-x-28 mt-[16px]">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ceoIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.ceoName') }}</p>
                        <p class=" text-[16px]">{{ $t('team.ceoExpertist') }}</p>
                    </div>
                </div>

                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ctoIMG" alt="">
                    <div class="mt-[1rem] text-black whitespace-pre-line">
                        <p class="text-[20px]">{{ $t('team.ctoName') }}</p>
                        <p class="  text-[16px]">{{ $t('team.ctoExpertist') }}</p>
                    </div>
                </div>

                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="csoIMG" alt="">
                    <div class="mt-[1rem] text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.csoName') }}</p>
                        <p class=" text-[16px]">{{ $t('team.csoExpertist') }}</p>
                    </div>
                </div>
            </div>

            <div class="w-full flex justify-center gap-x-28 mt-[2.5rem]">
                <!-- <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="cmoIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.cmoName') }}</p>
                        <p class=" text-[16px]">{{ $t('team.cmoExpertist') }}</p>
                    </div>
                </div> -->

                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="csIMG" alt="">
                    <div class="mt-[1rem]  text-black  whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.csName') }}</p>
                        <p class=" text-[16px]">{{ $t('team.csExpertist') }}</p>
                    </div>
                </div>
                <div class="w-[300px]  text-center">
                    <img class="w-[200px] h-[200px]  object-cover rounded-full mx-auto" :src="cooIMG" alt="">
                    <div class="mt-[1rem] text-black  whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.cooName') }}</p>
                        <p class=" text-[16px]">{{ $t('team.cooExpertist') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- ------------------- -->
        <!-- ADD Co-Founding Team For Mobile -->
        <swiper v-if="compState.isMobile" class="bg-[#E6E6E6]" :loop="true" :slides-per-view="1" :space-between="0"
            :allow-touch-move="true" @swiper="onSwiper" >
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full  object-cover mx-auto" :src="ceoIMG" alt="">
                    <div class="mt-[1rem] text-black whitespace-pre-line">
                        <p>{{ $t('team.ceoName') }}</p>
                        <p class="text-sm">{{ $t('team.ceoExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ctoIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p>{{ $t('team.ctoName') }}</p>
                        <p class="text-sm">{{ $t('team.ctoExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="csoIMG" alt="">
                    <div class="mt-[1rem] text-black whitespace-pre-line">
                        <p>{{ $t('team.csoName') }}</p>
                        <p class="text-sm">{{ $t('team.csoExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <!-- <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-coverv mx-auto" :src="cmoIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p>{{ $t('team.cmoName') }}</p>
                        <p class="text-sm">{{ $t('team.cmoExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide> -->
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="csIMG" alt="">
                    <div class="mt-[1rem]  text-black  whitespace-pre-line">
                        <p>{{ $t('team.csName') }}</p>
                        <p class="text-sm">{{ $t('team.csExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="cooIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p>{{ $t('team.cooName') }}</p>
                        <p class="text-sm">{{ $t('team.cooExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <div class="absolute bottom-[64px] flex justify-between w-[80%] mx-10">
                <div @click="onBack" class="w-[40px] h-[40px] z-10 hover:cursor-pointer opacity-75 left-0">
                    <img class="w-full h-full" :src="backIcon" alt="">
                </div>
                <div @click="onNext" class="w-[40px] h-[40px] z-10 hover:cursor-pointer opacity-75 right-0">
                    <img class="w-full h-full" :src="nextIcon" alt="">
                </div>
            </div>

        </swiper>

        <!-- ADD Cross-Functional Team -->
        <div class="px-40 mt-12 mobile:px-5">
            <span class=" font-light text-trueeye-red text-[40px] mobile:text-[24px] leading-[84px]">{{
                $t('team.subHeader2') }}</span>
        </div>

        <div v-if="!compState.isMobile">
            <div class="w-full flex justify-center gap-x-28 mt-[16px]">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ux1IMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.ux1Name') }}</p>
                        <p class=" text-[16px]">{{ $t('team.ux1Expertist') }}</p>
                    </div>
                </div>

                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ux2IMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.ux2Name') }}</p>
                        <p class=" text-[16px]">{{ $t('team.ux2Expertist') }}</p>
                    </div>
                </div>

                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="devIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.devName') }}</p>
                        <p class=" text-[16px]">{{ $t('team.devExpertist') }}</p>
                    </div>
                </div>
            </div>

            <div class="w-full flex justify-center gap-x-28 mt-[2.5rem]">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ds1IMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.ds1Name') }}</p>
                        <p class=" text-[16px]">{{ $t('team.ds1Expertist') }}</p>
                    </div>
                </div>

                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ds2IMG" alt="">
                    <div class="mt-[1rem]  text-black  whitespace-pre-line">
                        <p class=" text-[20px]">{{ $t('team.ds2Name') }}</p>
                        <p class=" text-[16px]">{{ $t('team.ds2Expertist') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- -------------------- -->

        <!-- ADD Cross-Functional Team For Mobile -->
        <swiper v-if="compState.isMobile" :loop="true" :slides-per-view="1" :space-between="0" :allow-touch-move="true"
            @swiper="onSwiper2" class="bg-[#E6E6E6]">
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class="w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ux1IMG" alt="">
                    <div class="mt-[1rem] text-black whitespace-pre-line">
                        <p>{{ $t('team.ux1Name') }}</p>
                        <p class="text-sm">{{ $t('team.ux2Expertist') }}</p>
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full object-cover mx-auto" :src="ux2IMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p>{{ $t('team.ux2Name') }}</p>
                        <p class="text-sm">{{ $t('team.ux2Expertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px]  rounded-full object-cover mx-auto" :src="devIMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p>{{ $t('team.devName') }}</p>
                        <p class="text-sm">{{ $t('team.devExpertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px]  rounded-full object-cover mx-auto" :src="ds1IMG" alt="">
                    <div class="mt-[1rem]  text-black whitespace-pre-line">
                        <p>{{ $t('team.ds1Name') }}</p>
                        <p class="text-sm">{{ $t('team.ds1Expertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="pt-10 w-full flex items-center justify-center">
                <div class="w-[300px] text-center">
                    <img class=" w-[200px] h-[200px] rounded-full  object-cover mx-auto" :src="ds2IMG" alt="">
                    <div class="mt-[1rem] text-black  whitespace-pre-line">
                        <p>{{ $t('team.ds2Name') }}</p>
                        <p class="text-sm">{{ $t('team.ds2Expertist') }}</p>
                    </div>
                </div>
            </swiper-slide>
            <div class="absolute bottom-[64px] flex justify-between w-[80%] mx-10">
                <div @click="onBack2" class="w-[40px] h-[40px] z-10 hover:cursor-pointer opacity-75 left-0">
                    <img class="w-full h-full" :src="backIcon" alt="">
                </div>
                <div @click="onNext2" class="w-[40px] h-[40px] z-10 hover:cursor-pointer opacity-75 right-0">
                    <img class="w-full h-full" :src="nextIcon" alt="">
                </div>
            </div>
        </swiper>
        <!-- -------------------- -->
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useMediaQuery } from '@vueuse/core'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'
import backIcon from '../assets/TrueEye/icon/arrow 7.png'
import nextIcon from '../assets/TrueEye/icon/arrow 6.png'

import ceoIMG from '../assets/TrueEye/Team/K_nui.png'
import ctoIMG from '../assets/TrueEye/Team/K_vee.png'
import csoIMG from '../assets/TrueEye/Team/K_oat.png'

import csIMG from '../assets/TrueEye/Team/K_ball.png'
import cooIMG from '../assets/TrueEye/Team/K_toey.png'
import ux1IMG from '../assets/TrueEye/Team/K_aod.png'
import ux2IMG from '../assets/TrueEye/Team/K_sand.png'
import devIMG from '../assets/TrueEye/Team/K_new.png'
import ds1IMG from '../assets/TrueEye/Team/K_mon.png'
import ds2IMG from '../assets/TrueEye/Team/K_tle.png'

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)')
})

const onSwiper = (swiper) => {
  compState.swiper = swiper
}
const onSwiper2 = (swiper) => {
  compState.swiper2 = swiper
}

const onNext = () => {
  compState.swiper.slideNext()
}
const onBack = () => {
  compState.swiper.slidePrev()
}

const onNext2 = () => {
  compState.swiper2.slideNext()
}
const onBack2 = () => {
  compState.swiper2.slidePrev()
}
</script>
