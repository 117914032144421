<template>
    <div class="relative w-screen" :key="$i18n.locale">
        <div class="w-full absolute -z-10">
            <swiper
                class="w-full overflow-x-hidden mobile:object-cover"
                :loop="true"
                :slides-per-view="1"
                :space-between="0"
                :allow-touch-move="false"
                :autoplay="{delay: 2000, disableOnInteraction: false}"
                @slideChange="onSlideChange"
                :modules="[Autoplay]"
            >
                <swiper-slide v-for="n in 3" :key="n">
                    <img :src="imgList[n-1]" alt="" class=" w-full mobile:h-[540px] mobile:object-cover" :class="imgObjPosition">
                </swiper-slide>
            </swiper>
        </div>
        <div v-if="compState.isMobile" class="w-full absolute top-0 h-[540px] bg-gradient">
        </div>
        <div class="relative w-full h-[calc(100vw*(9/16))] mobile:h-[540px]">
            <img v-if="!compState.isMobile" :src="trueEyeIcon" class="absolute left-10 top-11 h-[85px]" alt="">
            <div class="absolute right-10 top-5 mobile:right-2 mobile:top-0 flex h-12 justify-end items-center select-none">
              <div @click="() => $i18n.locale = 'th'" class=" px-2 border-[#999999] border-r-[1px] hover:cursor-pointer flex justify-center items-center">
                <span class=" font-light text-[12px]" :class="$i18n.locale === 'th' && 'text-trueeye-red'">TH</span>
              </div>
              <div @click="() => $i18n.locale = 'en'" class=" px-2 hover:cursor-pointer flex justify-center items-center">
                <span class=" font-light text-[12px]" :class="$i18n.locale === 'en' && 'text-trueeye-red'">ENG</span>
              </div>
            </div>
            <img v-if="!compState.isMobile" :src="trueEyeLogo" class="absolute right-10 top-16 h-[69px]" alt="">
            <div class="absolute w-full h-[65%] bottom-0">
              <div class="flex justify-center">
                <div class="w-[90%] h-[11rem] mobile:w-full bg-[#FF0008] rounded-[45px] mobile:rounded-[0] bg-opacity-10 flex justify-end">
                  <div class="w-[45%] mobile:w-full mobile:mr-5 h-full flex flex-col mobile:items-end justify-center">
                    <span v-if="!compState.isMobile" class=" text-trueeye-red font-[275] text-[60px] leading-[90px]">TRUEEYE</span>
                    <span v-if="!compState.isMobile" class=" text-trueeye-red font-[275] text-[40px] leading-[60px] -mt-2">AI FOR LIFE</span>
                    <img v-if="compState.isMobile" class="w-[176px] h-[69px]" :src="trueEyeLogo" alt="">
                  </div>
                </div>
              </div>
              <div class="mt-[2.125rem] flex justify-center mx-2">
                <div class="w-[90%] flex justify-end">
                  <div class="w-[45%] mobile:w-full">
                    <div>
                      <p class="text-base font-medium mobile:text-center">We prevent sickness with fast <br v-if="compState.isMobile">and painless screening solution.
                      </p>
                    </div>
                    <div class="flex items-center text-black mt-6 mobile:justify-around">
                      <span class="font-medium text-[24px] mobile:text-[20px] transition-all duration-200" :class="(compState.slideIndex === 0) && 'text-trueeye-red'">EASY</span>
                      <div class="w-1 h-1 rounded-full bg-black mx-5 mobile:mx-0"></div>
                      <span class="font-medium text-[24px] mobile:text-[20px] transition-all duration-200" :class="(compState.slideIndex === 1) && 'text-trueeye-red'">PAINLESS</span>
                      <div class="w-1 h-1 rounded-full bg-black mx-5 mobile:mx-0"></div>
                      <span class="font-medium text-[24px] mobile:text-[20px] transition-all duration-200" :class="(compState.slideIndex === 2) && 'text-trueeye-red'">ACCESSIBLE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!compState.isMobile" class=" absolute right-0 bottom-0 flex gap-[0.625rem] px-11 py-[1.625rem]">
              <div v-for="n in 3" :key="n" class="w-[11px] h-[11px] rounded-full shadow-[4px_4px_10px_rgba(0,0,0,0.15)] transition-all duration-200" :class="(n === (compState.slideIndex+1)) ? 'bg-[#999999]' : 'bg-[#D9D9D9]'"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, computed } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import 'swiper/css'
import { useMediaQuery } from '@vueuse/core'

import coverImg1 from '../assets/TrueEye/Cover photos/Cover_1_resize.jpg'
import coverImg2 from '../assets/TrueEye/Cover photos/Cover_2_resize.jpg'
import coverImg3 from '../assets/TrueEye/Cover photos/Cover_3_resize.jpg'
import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'
import trueEyeLogo from '../assets/TrueEye/Logo/Logo on footer.png'

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  slideIndex: 0
})

const imgList = [coverImg1, coverImg2, coverImg3]

const onSlideChange = (swiper) => {
  compState.slideIndex = swiper.realIndex
}
const imgObjPosition = computed(() => {
  let position
  compState.slideIndex === 0
    ? position = 'mobile:object-[25%]'
    : compState.slideIndex === 1
      ? position = 'mobile:object-[18%]'
      : compState.slideIndex === 2
        ? position = 'mobile:object-[26%]'
        : position = 'mobile:object-[20%]'
  return position
})
</script>

<style scope>
  .bg-gradient{
    background:  linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  }
</style>
