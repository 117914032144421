<template>
    <div class="mt-32 relative w-screen  mobile:mt-24" :key="$i18n.locale">
        <div v-if="!compState.isMobile" class="absolute w-full h-full -z-10">
            <div class="relative w-full h-full overflow-x-hidden">
                <img class=" absolute -right-1 top-[27.5rem] h-[348px] w-[913px] opacity-20" :src="bgAward" alt="">
                <img class=" absolute -right-1 top-[61.5rem] h-[76px] w-[913px] opacity-20" :src="bgNetwork" alt="">
            </div>
        </div>
        <div v-if="compState.isMobile" class="flex justify-center w-full">
            <div class="w-[90%] flex gap-5 items-center">
                <img :src="trueEyeIcon" class="h-[85px] mobile:h-[72px] mobile:w-[72px] " alt="">
                <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('customer.header') }}</span>
            </div>
        </div>
        <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10">
            <img :src="trueEyeIcon" class="h-[85px]" alt="">
            <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('customer.header') }}</span>
        </div>
        <div class="w-full flex justify-center mt-[6.25rem] mobile:mt-16 gap-x-[5rem] mobile:gap-6">
            <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px]" :src="hos2" alt="">
            <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px]" :src="hos3" alt="">
        </div>
        <div class="w-full mt-[6.25rem] mobile:mt-8">
            <p
                class="pl-[9rem] font-light text-trueeye-red text-[56px] leading-[84px] mobile:w-full mobile:text-center mobile:pl-0 mobile:text-[30px] mobile:leading-[45px]">
                {{ $t('customer.awardHeader') }}</p>
            <div class=" w-full mt-[6.25rem] mobile:mt-8 flex justify-center">
                <div
                    class="flex max-w-[1366px] gap-x-[5rem] gap-y-[2.5rem] mobile:gap-x-6 mobile:gap-y-5 flex-wrap justify-center">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-1" :src="award1" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-2" :src="award2" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-4" :src="award3" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-3" :src="award4" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-5" :src="award5" alt="">
                </div>
            </div>
        </div>
        <div class="w-full mt-[6.25rem] mobile:mt-8">
            <p
                class="pl-[9rem] font-light text-trueeye-red text-[56px] leading-[84px] mobile:w-full mobile:text-center mobile:pl-0 mobile:text-[30px] mobile:leading-[45px]">
                {{ $t('customer.networkHeader') }}</p>
            <div class=" w-full mt-[6.25rem] mobile:mt-8 flex justify-center">
                <div
                    class="flex max-w-[1366px] gap-x-[5rem] gap-y-[2.5rem] mobile:gap-x-6 mobile:gap-y-5 flex-wrap justify-center">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-4" :src="network1" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-5" :src="network2" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-6" :src="network3" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-2" :src="network4" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-1" :src="network5" alt="">
                    <img class="w-[303px] h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-3" :src="network6" alt="">
                    <!-- ADD Networks -->
                    <img class="w-auto h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-2 object-cover" :src="network7"
                        alt="">
                    <img class="w-auto h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-1 object-cover" :src="network8"
                        alt="">
                    <img class="w-auto h-[129px] mobile:w-[176px] mobile:h-[75px] mobile:order-3 object-cover" :src="network9"
                        alt="">

                    <!-- ------------ -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useMediaQuery } from '@vueuse/core'

import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'
import bgAward from '../assets/TrueEye/Graphics/Graphic 7.png'
import bgNetwork from '../assets/TrueEye/Graphics/Graphic 8.png'
import hos2 from '../assets/TrueEye/Logo/Hos_1_resize.jpg'
import hos3 from '../assets/TrueEye/Logo/Hos_2_resize.jpg'
import award1 from '../assets/TrueEye/Logo/Award_1_resize.jpg'
import award2 from '../assets/TrueEye/Logo/Award_2_resize.jpg'
import award3 from '../assets/TrueEye/Logo/Award_3_resize.jpg'
import award4 from '../assets/TrueEye/Logo/Award_4_resize.jpg'
import award5 from '../assets/TrueEye/Logo/Award_5_resize.jpg'
import network1 from '../assets/TrueEye/Logo/Network_1_resize.jpg'
import network2 from '../assets/TrueEye/Logo/Network_5_resize.jpg'
import network3 from '../assets/TrueEye/Logo/Network_6_resize.jpg'
import network4 from '../assets/TrueEye/Logo/Network_4_resize.jpg'
import network5 from '../assets/TrueEye/Logo/Network_2_resize.jpg'
import network6 from '../assets/TrueEye/Logo/Network_3_resize.jpg'
import network7 from '../assets/TrueEye/Logo/Network_7.png'
import network8 from '../assets/TrueEye/Logo/Network_8.png'
import network9 from '../assets/TrueEye/Logo/Network_9.png'
const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)')
})
</script>
