<template>
  <div ref="aboutTop" class="w-screen mt-16 mobile:mt-8" :key="$i18n.locale">
    <div v-if="compState.isMobile" class="flex justify-center w-full">
      <div class="w-[90%] flex gap-5 items-center">
        <img :src="trueEyeIcon" class="h-[95px] mobile:h-[72px] mobile:w-[72px] " alt="">
        <span class=" font-light text-trueeye-red text-[56px] mobile:text-[30px]">{{ $t('about.header') }}</span>
      </div>
    </div>
    <div class="w-full flex justify-center mobile:mt-8">
      <img class="w-[90%]" :src="lifeIMG" alt="">
    </div>
    <div class="relative mt-[6.25rem] w-full mobile:mt-8">
      <div v-if="!compState.isMobile" class="flex gap-5 items-center ml-10">
        <img :src="trueEyeIcon" class="h-[95px]" alt="">
        <span class=" font-light text-trueeye-red text-[56px] leading-[84px]">{{ $t('about.header') }}</span>
      </div>
      <div class="flex justify-center w-full mt-[52px]">
        <div
          class="w-[67%] mobile:w-[85%] mobile:font-light mobile:text-base mobile:overflow-hidden transition-all duration-500 ease-linear">
          <!-- :style="(compState.isMobile) && (!compState.isExpand) ? 'height: 10rem' : `height: ${mobileDetailHeight}px;`">  -->
          <div ref="detailComp">
            <!-- ADD Header About Detail -->
            <div v-if="!compState.isMobile" class="w-full flex justify-center ">
              <div class="w-[80%] text-center">
                <p class="text-trueeye-red font-medium text-xl">{{ $t('about.headerDetail') }}</p>
              </div>
            </div>
            <!-- ----------------------- -->

            <!-- ADD Main Detail -->
            <p class="mt-[32px] whitespace-pre-line font-light text-center mobile:text-sm">
              {{ $t('about.mainDetail', {
                newline: '',
                space: ''
              }) }}
            </p>
            <!-- ---------------- -->

            <!-- ADD Button Read More  -->

            <div class="flex justify-center items-center mt-[32px]">
              <button @click="onPopup()"
                class="bg-white hover:bg-gray-100 font-light text-gray-400 py-2 px-4 border border-gray-400  text-sm rounded">
                Read more
              </button>
            </div>

            <!-- --------------------- -->

            <!-- ADD Popup Content  -->
            <div v-if="compState.isPopup"
              class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-80 z-10"
              @click="onPopup">
              <div class="bg-white rounded-lg shadow w-[60%] mobile:w-[80%]">
                <div class="flex items-start justify-between p-5 border-b rounded-t">
                  <span class="font-light text-trueeye-red text-[40px] mobile:text-xl w-[80%]">{{
                    $t('about.header') }}</span>
                </div>
                <div class="p-6 space-y-6 h-[50vh] mobile:h-[65vh] overflow-y-auto " @click.stop>
                  <div ref="detailComp">
                    <p class=" whitespace-pre-line font-light mobile:text-sm">
                      {{ $t('about.detail1', { newline: '\n', space: '&emsp;&emsp;' }) }}
                    </p>
                    <ul>
                      <li><span class="font-light mobile:text-sm whitespace-pre-line">{{ $t('about.detail2', {
                        newline: '\n',
                        space: '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                      <li><span class="font-light mobile:text-sm ">{{ $t('about.detail3', {
                        newline: '\n',
                        space: '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                      <li><span class="font-light mobile:text-sm">{{ $t('about.detail4', {
                        newline: '\n',
                        space: '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                      <li><span class="font-light mobile:text-sm">{{ $t('about.detail5', {
                        newline: '\n',
                        space: '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                    </ul>
                    <p class="whitespace-pre-line font-light mobile:text-sm">
                      {{ $t('about.detail6', { newline: '\n', space: '&emsp;&emsp;&ensp;' }) }}
                    </p>
                    <p class="whitespace-pre-line font-normal mobile:text-sm">
                      {{ $t('about.detail7', { newline: '\n', space: '&emsp;&emsp;&ensp;' }) }}
                    </p>
                    <ul>
                      <li><span class="font-light mobile:text-sm">{{ $t('about.detail8', {
                        newline: '\n', space:
                          '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                      <li><span class="font-light mobile:text-sm">{{ $t('about.detail9', {
                        newline: '\n', space:
                          '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                      <li><span class="font-light mobile:text-sm">{{ $t('about.detail10', {
                        newline: '\n', space:
                          '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                      <li><span class="font-light mobile:text-sm">{{ $t('about.detail11', {
                        newline: '\n', space:
                          '&emsp;&emsp;&ensp;'
                      }) }}</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- ----------------- -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="compState.isMobile" class="w-full flex justify-center mt-4">
        <img @click="onExpand" class="w-11 h-11 transition-all" :class="compState.isExpand && 'rotate-180'"
          :src="expandIcon" alt="">
      </div> -->
  </div>
  <!-- ADD Columns -->
  <div class="mx-14 grid grid-cols-4 mobile:grid-cols-1 gap-8  mt-[80px]">
    <div class="text-center flex flex-col items-center">
      <img class="h-[75px] w-[75px] mobile:h-[56px] mobile:w-[56px]" :src="fastIcon" alt="">
      <p class="my-2 font-medium text-lg mobile:text-[20px]">{{ $t('about.headDetail8') }}</p>
      <p class="font-light px-[24px] text-sm">{{ $t('about.detail8') }}</p>
    </div>
    <div class=" text-center flex flex-col items-center ">
      <img class="h-[75px] w-[75px] mobile:h-[56px] mobile:w-[56px]" :src="nonInvaIcon" alt="">
      <p class="my-2 font-medium text-lg mobile:text-[20px]">{{ $t('about.headDetail9') }}</p>
      <p class="font-light px-[24px] text-sm">{{ $t('about.detail9') }}</p>
    </div>
    <div class="text-center flex flex-col items-center">
      <img class="h-[75px] w-[75px] mobile:h-[56px] mobile:w-[56px]" :src="accsesibleIcon" alt="">
      <p class="my-2 font-medium text-lg mobile:text-[20px]">{{ $t('about.headDetail10') }}</p>
      <p class="font-light px-[24px] text-sm">{{ $t('about.detail10') }}</p>
    </div>
    <div class="text-center flex flex-col items-center">
      <img class="h-[75px] w-[75px] mobile:h-[56px] mobile:w-[56px]" :src="trusteIcon" alt="">
      <p class="my-2 font-medium text-lg mobile:text-[20px]">{{ $t('about.headDetail11') }}</p>
      <p class="font-light px-[24px] text-sm">{{ $t('about.detail11') }}</p>
    </div>
    <!-- </div> -->
    <!-- --------------- -->
  </div>
</template>

<script setup>
import { ref, reactive, onUpdated, onBeforeUnmount, onMounted } from 'vue'
import lifeIMG from '../assets/TrueEye/Graphics/About_resize.jpg'
import trueEyeIcon from '../assets/TrueEye/Logo/Logo small.png'
// import expandIcon from '../assets/TrueEye/icon/expand.png'
import { useMediaQuery } from '@vueuse/core'
// import crossIcon from '../assets/TrueEye/icon/cross.png'
import accsesibleIcon from '../assets/TrueEye/icon/ACCESSIBLE_AND_SCALABLE_icon.png'
import fastIcon from '../assets/TrueEye/icon/Fast_and_easy.png'
import trusteIcon from '../assets/TrueEye/icon/TRUSTE_icon.png'
import nonInvaIcon from '../assets/TrueEye/icon/non_invasive.png'

const compState = reactive({
  isMobile: useMediaQuery('(max-aspect-ratio: 13/10)'),
  isExpand: false,
  isPopup: false,
  aboutHeight: 0
})
const detailComp = ref()
const aboutTop = ref()

onMounted(() => {
  window.addEventListener('resize', handleOrientationChange)
  compState.aboutHeight = detailComp.value?.clientHeight + 24
})

onUpdated(() => {
  compState.aboutHeight = detailComp.value?.clientHeight + 24
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleOrientationChange)
})
// const mobileDetailHeight = computed(() => {
//   return compState.aboutHeight
// })
// const onExpand = () => {
//   compState.isExpand = !compState.isExpand
//   !compState.isExpand && (aboutTop.value.scrollIntoView({ behavior: 'smooth' }))
// }
const handleOrientationChange = () => {
  compState.aboutHeight = detailComp.value?.clientHeight + 24
}
// ADD Popup Readmore Function
const onPopup = () => {
  compState.isPopup = !compState.isPopup
  document.body.classList.toggle('no-scroll')
}

</script>
<style scoped>
ul {
  list-style: none;
  list-style-position: inside;
}

ul li:before {
  content: "•";
  margin: 0 0.5rem 0 0.5rem;
}</style>
